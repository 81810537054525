import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";

import ModAnimation from "../../../components/Chronology/Modules/ModAnimation";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 17",
  week: "Semana 3",
  month: "Mar",
  day: "30",
  monthNumber: "03",
  date: "2020-03-30",
  path: "/cronologia/semana-03#dia-30-mar",
};

const Day17 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 6.398 nuevos positivos y 812 personas fallecidas.
          Permanecen hospitalizadas 46.617 personas, 3.220 más que el día
          anterior, y 1.603 reciben el alta. El número de casos ingresados en la
          UCI asciende a 5.231, lo que supone un aumento de 324 personas.
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 8,6 % y la tasa de recuperados del 17,4 %.
        </ModText>
        <ModTwoCols
          src="/images/svg/30_mar_funeraria.svg"
          alt="vehículo funerario"
          small={false}
          inverted={false}
        >
          El Gobierno ha prohibido que los precios de los servicios funerarios
          se incrementen a causa del COVID-19. Así, durante el periodo de
          vigencia del estado de alarma, los precios de los servicios funerarios
          no podrán ser superiores a los que existían antes del 14 de marzo.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/30_mar_ministerio_edu.svg"
          alt="lineas móviles"
          small={false}
          inverted={true}
        >
          El <strong>Ministerio de Educación y Formación Profesional</strong>{" "}
          (MEFP) va a facilitar 20.000 líneas móviles de Movistar mediante el
          envío de tarjetas SIM de 40 Gbs mensuales por línea a estudiantes de
          Bachillerato y FP de Grado Medio y Grado Superior con dificultades
          para continuar su formación de manera telemática durante el estado de
          alarma, gracias a la colaboración de Telefónica, Cisco e IBM.
        </ModTwoCols>
        <ModText>
          El <strong>Instituto de Salud Carlos III</strong> (ISCIII) lidera la
          coordinación de estudios virológicos sobre la COVID-19 dentro de la
          red europea multidisciplinar para la investigación, prevención y
          control de esta enfermedad, a través del proyecto I-MOVE-COVID-19.
          Este es uno de los proyectos europeos incluidos en la convocatoria
          exprés del programa de investigación e innovación de la UE, Horizonte
          2020, que la Comisión Europea ha lanzado para investigar diferentes
          aspectos del virus SARS-CoV-2.
        </ModText>
        <ModAnimation svg="/images/anim/30_mar_ministerio_transportes.svg" />
        <ModText>
          El <strong>ministro de Transportes, Movilidad y Agenda Urbana</strong>{" "}
          ha anunciado que desde el 31 de marzo todas las administraciones
          públicas deberán ajustar su oferta de servicios de transporte público
          a los horarios de fin de semana.
        </ModText>
        <ModTwoCols
          src="/images/svg/30_mar_ministerio_sanidad.svg"
          alt="tanatorio"
          small={false}
          inverted={false}
        >
          <strong>
            El Ministerio de Sanidad ha prohibido todos los velatorios
          </strong>
          , independientemente de la causa del fallecimiento y de si se celebran
          en instalaciones públicas o privadas, incluido los domicilios
          particulares, para limitar la propagación y el contagio por el
          COVID-19.
        </ModTwoCols>
      </ContentRight>
    </LayoutDay>
  );
};

export default Day17;
